import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import { FaWhatsapp, FaEnvelope, FaInstagram } from "react-icons/fa";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Hubungi Kami</h1>
              <div>
                <h4>Aneka Warna Benhil</h4>
                <div>
                  <a href="https://wa.me/6281932621860"><FaWhatsapp /> +62-819-3262-1860</a>
                </div>
                <div>
                  <a href="mailto:info@anekawarna.com"><FaEnvelope /> info@anekawarna.com</a>
                </div>
                <div>
                  <a href="https://www.instagram.com/anekawarnabenhil/"><FaInstagram /> anekawarnabenhil</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
